<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Data Master Shift"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-row>
        <b-col md="12" lg="12" xl="12">
          <b-form>
            <b-form-group label="Kategori Shift" label-cols-md="3">
              <b-form-input
                type="text"
                :state="checkIfValid('kategoriShift')"
                v-model="$v.data.kategoriShift.$model"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Posisi" label-cols-md="3">
              <multiselect
                :state="checkIfValid2('masterPosisiIdEdit')"
                v-model="$v.jadwal.masterPosisiIdEdit.$model"
                :options="posisi"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="namaPosisi"
                track-by="masterPosisiId"
                placeholder="-- Pilih Posisi--"
              ></multiselect>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Jam Masuk dan Pulang" label-cols-md="3">
            <b-row>
              <b-col
                ><b-form-timepicker
                  locale="id"
                  :state="checkIfValid('jamMasuk')"
                  v-model="$v.data.jamMasuk.$model"
                  placeholder="-- Pilih Jam Masuk Kerja --"
                ></b-form-timepicker
              ></b-col>
              <b-col
                ><b-form-timepicker
                  :state="checkIfValid('jamKeluar')"
                  v-model="$v.data.jamKeluar.$model"
                  locale="id"
                  placeholder="-- Pilih Jam Pulang Kerja --"
                ></b-form-timepicker
              ></b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Jam Istirahat" label-cols-md="3">
            <b-row>
              <b-col>
                <b-form-timepicker
                  :state="checkIfValid('jamIstirahatKeluar')"
                  v-model="$v.data.jamIstirahatKeluar.$model"
                  locale="id"
                  placeholder="-- Pilih Jam Istirahat Mulai --"
                ></b-form-timepicker
              ></b-col>
              <b-col
                ><b-form-timepicker
                  :state="checkIfValid('jamIstirahatMasuk')"
                  v-model="$v.data.jamIstirahatMasuk.$model"
                  locale="id"
                  placeholder="-- Pilih Jam Istirahat Selesai --"
                ></b-form-timepicker
              ></b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          variant="primary"
          @click="updateMasterShift()"
          :disabled="busy || !Valid"
          >{{ button }}</b-button
        >
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-delete-shift1"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menghapus data barang ini?</p>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-delete-shift')"
        >
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy" @click="hapus"
          >Hapus</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "modalEditMasterShift",
  props: ["data", "edit", "posisi"],
  components: {
    Multiselect,
  },
  data() {
    return {
      jadwal: {
        masterPosisiIdEdit: "",
      },
      button: "Simpan",
      busy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isValid2() {
      return !this.$v.jadwal.$invalid;
    },
    Valid() {
      return this.isValid && this.isValid2;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      kategoriShift: {
        required,
      },
      jamMasuk: {
        required,
      },
      jamKeluar: {
        required,
      },
      jamIstirahatMasuk: {
        required,
      },
      jamIstirahatKeluar: {
        required,
      },
    },
    jadwal: {
      masterPosisiIdEdit: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    checkIfValid2(fieldName) {
      const field = this.$v.jadwal[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async updateMasterShift() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.masterShiftId = vm.data.id;
      vm.data.masterPosisiId = vm.jadwal.masterPosisiIdEdit.masterPosisiId;
      axios
        .post(ipBackend + "masterShift/update", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.$bvModal.hide("modal-edit");
            vm.$v.$reset();
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENGUBAH MASTER KATEGORI SHIFT",
              showing: true,
            });
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (newVal != oldVal) {
        console.log(newVal, "ediot", this.data);
        for (let i = 0; i < this.posisi.length; i++) {
          if (this.data.masterPosisiId == this.posisi[i].masterPosisiId) {
            this.jadwal.masterPosisiIdEdit = this.posisi[i];
          }
        }
      }
    },
  },
};
</script>
