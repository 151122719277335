<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Input Data Master Shift"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-row>
        <b-col md="12" lg="12" xl="12">
          <b-form>
            <b-form-group label="Kategori Shift" label-cols-md="3">
              <b-form-input
                type="text"
                :state="checkIfValid('kategoriShift')"
                v-model="$v.jadwal.kategoriShift.$model"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Posisi" label-cols-md="3">
              <multiselect
                :state="checkIfValid('masterPosisiIdEdit')"
                v-model="$v.jadwal.masterPosisiIdEdit.$model"
                :options="posisi"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="namaPosisi"
                track-by="masterPosisiId"
                placeholder="-- Pilih Posisi--"
              ></multiselect>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Jam Masuk dan Pulang" label-cols-md="3">
            <b-row>
              <b-col
                ><b-form-timepicker
                  locale="id"
                  :state="checkIfValid('jamMasuk')"
                  v-model="$v.jadwal.jamMasuk.$model"
                  placeholder="-- Pilih Jam Masuk Kerja --"
                ></b-form-timepicker
              ></b-col>
              <b-col
                ><b-form-timepicker
                  :state="checkIfValid('jamKeluar')"
                  v-model="$v.jadwal.jamKeluar.$model"
                  locale="id"
                  placeholder="-- Pilih Jam Pulang Kerja --"
                ></b-form-timepicker
              ></b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Jam Istirahat" label-cols-md="3">
            <b-row>
              <b-col>
                <b-form-timepicker
                  :state="checkIfValid('jamIstirahatKeluar')"
                  v-model="$v.jadwal.jamIstirahatKeluar.$model"
                  locale="id"
                  placeholder="-- Pilih Jam Istirahat Mulai --"
                ></b-form-timepicker
              ></b-col>
              <b-col
                ><b-form-timepicker
                  :state="checkIfValid('jamIstirahatMasuk')"
                  v-model="$v.jadwal.jamIstirahatMasuk.$model"
                  locale="id"
                  placeholder="-- Pilih Jam Istirahat Selesai --"
                ></b-form-timepicker
              ></b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          variant="primary"
          @click="updateMasterShift()"
          :disabled="busy || !isValid"
          >{{ button }}</b-button
        >
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-delete-shift1"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menghapus data barang ini?</p>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-delete-shift')"
        >
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy" @click="hapus"
          >Hapus</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "modalInputMasterShift",
  components: {
    Multiselect,
  },
  props: ["posisi"],
  data() {
    return {
      jadwal: {
        kategoriShift: "",
        namaHari: "",
        jamMasuk: "",
        jamKeluar: "",
        jamIstirahatKeluar: "",
        jamIstirahatMasuk: "",
        masterPosisiIdEdit: "",
      },
      button: "Simpan",
      busy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.jadwal, null, 4);
    },
    isValid() {
      return !this.$v.jadwal.$invalid;
    },
  },
  mixins: [validationMixin],
  validations: {
    jadwal: {
      kategoriShift: {
        required,
      },
      masterPosisiIdEdit: {
        required,
      },
      jamMasuk: {
        required,
      },
      jamKeluar: {
        required,
      },
      jamIstirahatMasuk: {
        required,
      },
      jamIstirahatKeluar: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.jadwal[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    // checkIfValid2(fieldName) {
    //   const field = this.$v.data[fieldName];
    //   if (!field.$dirty) {
    //     return null;
    //   }
    //   return !(field.$invalid || field.$model === "");
    // },
    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length;
    //   this.currentPage = 1;
    // },
    // hapus() {
    //   this.data.bulkShift.splice(this.idHapus, 1);
    //   this.check();
    //   this.$bvModal.hide("modal-delete-shift1");
    // },
    // check() {
    //   let vm = this;
    //   if (vm.data.kategoriShift) {
    //     if (vm.data.bulkShift.length > 0) {
    //       vm.joss = true;
    //     } else {
    //       vm.joss = false;
    //     }
    //   } else {
    //     vm.joss = false;
    //   }
    // },
    // tambahShift() {
    //   let vm = this;
    //   let table = {};
    //   table.namaHari = vm.jadwal.namaHari;
    //   table.jamMasuk = vm.jadwal.jamMasuk;
    //   table.jamKeluar = vm.jadwal.jamKeluar;
    //   table.jamIstirahatKeluar = vm.jadwal.jamIstirahatKeluar;
    //   table.jamIstirahatMasuk = vm.jadwal.jamIstirahatMasuk;
    //   table.kategoriShift = vm.data.kategoriShift;
    //   if (vm.jadwal.namaHari == "senin") {
    //     table.no = 1;
    //   } else if (vm.jadwal.namaHari == "Selasa") {
    //     table.no = 2;
    //   } else if (vm.jadwal.namaHari == "Rabu") {
    //     table.no = 3;
    //   } else if (vm.jadwal.namaHari == "Kamis") {
    //     table.no = 4;
    //   } else if (vm.jadwal.namaHari == "Jumat") {
    //     table.no = 5;
    //   } else if (vm.jadwal.namaHari == "Sabtu") {
    //     table.no = 6;
    //   } else if (vm.jadwal.namaHari == "Minggu") {
    //     table.no = 7;
    //   }
    //   vm.data.bulkShift.push(table);
    //   vm.sortShift();
    //   vm.jadwal.namaHari = "";
    //   vm.check();
    //   this.$v.$reset();
    // },
    sortShift() {
      let vm = this;
      let y = [];
      y = vm.data.bulkShift;
      y.sort(function (a, b) {
        return a.no - b.no;
      });
      vm.sortJadwal = y;
    },
    // async fecthShift() {
    //   let vm = this;
    //   let shift = await axios.get(
    //     ipBackend + "masterShift/listShiftByMasterShift/" + vm.data.id
    //   );
    //   let shifts = shift.data.data;
    //   for (let i = 0; i < shifts.length; i++) {
    //     shifts[i].kategoriShift = vm.data.kategoriShift;
    //     if (shifts[i].namaHari == "senin") {
    //       shifts[i].no = 1;
    //     } else if (shifts[i].namaHari == "Selasa") {
    //       shifts[i].no = 2;
    //     } else if (shifts[i].namaHari == "Rabu") {
    //       shifts[i].no = 3;
    //     } else if (shifts[i].namaHari == "Kamis") {
    //       shifts[i].no = 4;
    //     } else if (shifts[i].namaHari == "Jumat") {
    //       shifts[i].no = 5;
    //     } else if (shifts[i].namaHari == "Sabtu") {
    //       shifts[i].no = 6;
    //     } else if (shifts[i].namaHari == "Minggu") {
    //       shifts[i].no = 7;
    //     }
    //   }
    //   vm.data.bulkShift = shifts;
    //   vm.sortShift();
    //   vm.check()
    // },
    async updateMasterShift() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.jadwal.masterPosisiId = vm.jadwal.masterPosisiIdEdit.masterPosisiId;
      axios
        .post(ipBackend + "masterShift/register", vm.jadwal)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.$bvModal.hide("modal-input");
            vm.reset();
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER KATEGORI SHIFT",
              showing: true,
            });
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    reset() {
      let vm = this;
      vm.jadwal = {
        kategoriShift: "",
        jamMasuk: "",
        jamKeluar: "",
        jamIstirahatKeluar: "",
        jamIstirahatMasuk: "",
        masterPosisiIdEdit: "",
      };
      vm.$v.$reset();
    },
  },
  watch: {
    // edit: function (newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     this.fecthShift();
    //   }
    // },
  },
};
</script>
